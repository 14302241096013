import React, { useState } from 'react';
import ProvinceSelect from "../components/ui/province-select";
import DateSelect from "../components/ui/date-select";

const NewsletterForm = ({ onSubmissionComplete }) => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    surname: '',
    dateOfBirth: undefined,
    province: ''
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [customMessage, setCustomMessage] = useState('');
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);

  const provinces = [
    "Agrigento", "Alessandria", "Ancona", "Aosta", "Arezzo", "Ascoli Piceno", 
    "Asti", "Avellino", "Bari", "Barletta-Andria-Trani", "Belluno", "Benevento", 
    "Bergamo", "Biella", "Bologna", "Bolzano", "Brescia", "Brindisi", "Cagliari", 
    "Caltanissetta", "Campobasso", "Carbonia-Iglesias", "Caserta", "Catania", 
    "Catanzaro", "Chieti", "Como", "Cosenza", "Cremona", "Crotone", "Cuneo", 
    "Enna", "Fermo", "Ferrara", "Firenze", "Foggia", "Forlì-Cesena", "Frosinone", 
    "Genova", "Gorizia", "Grosseto", "Imperia", "Isernia", "La Spezia", "L'Aquila", 
    "Latina", "Lecce", "Lecco", "Livorno", "Lodi", "Lucca", "Macerata", "Mantova", 
    "Massa-Carrara", "Matera", "Messina", "Milano", "Modena", "Monza e Brianza", 
    "Napoli", "Novara", "Nuoro", "Ogliastra", "Olbia-Tempio", "Oristano", "Padova", 
    "Palermo", "Parma", "Pavia", "Perugia", "Pesaro e Urbino", "Pescara", "Piacenza", 
    "Pisa", "Pistoia", "Pordenone", "Potenza", "Prato", "Ragusa", "Ravenna", 
    "Reggio Calabria", "Reggio Emilia", "Rieti", "Rimini", "Roma", "Rovigo", 
    "Salerno", "Medio Campidano", "Sassari", "Savona", "Siena", "Siracusa", 
    "Sondrio", "Taranto", "Teramo", "Terni", "Torino", "Trapani", "Trento", 
    "Treviso", "Trieste", "Udine", "Varese", "Venezia", "Verbano-Cusio-Ossola", 
    "Vercelli", "Verona", "Vibo Valentia", "Vicenza", "Viterbo"
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'email' && value.length > 0 && !showAdditionalFields) {
      setShowAdditionalFields(true);
    }
  };

  const validateForm = () => {
    console.log('Validating form data:', formData);
    const { email, name, surname, dateOfBirth, province } = formData;
    
    if (!email) console.log('Email is empty');
    if (!name) console.log('Name is empty');
    if (!surname) console.log('Surname is empty');
    if (!dateOfBirth) console.log('Date of birth is empty');
    if (!province) console.log('Province is empty');
    if (!acceptPolicy) console.log('Policy not accepted');
    
    return email && name && surname && dateOfBirth && province && acceptPolicy;
  };

  const subscribeToNewsletter = async (formData) => {
    try {
      const response = await fetch('/api/subscribe-newsletter-full', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return {
          status: 'error',
          message: 'Si è verificato un errore durante l\'elaborazione della tua richiesta. Per favore, riprova più tardi.'
        };
      }
    } catch (error) {
      console.error('Subscription error:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with data:', formData);

    if (!validateForm()) {
      setSubmissionStatus('error');
      setCustomMessage('Per favore, compila tutti i campi richiesti.');
      return;
    }

    try {
      const result = await subscribeToNewsletter(formData);
      console.log('Newsletter subscription result:', result);
      setSubmissionStatus(result.status);
      setCustomMessage(result.message);
      if (onSubmissionComplete) {
        onSubmissionComplete(result);
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmissionStatus('error');
      setCustomMessage('Si è verificato un errore imprevisto. Per favore, riprova più tardi.');
    }
  };

  const renderResult = () => {
    const results = {
      success: {
        className: "bg-green-100 border-l-4 border-green-500 text-green-700",
        title: "Iscrizione confermata!",
      },
      email_already_present: {
        className: "bg-blue-100 border-l-4 border-blue-500 text-blue-700",
        title: "Informazione",
      },
      error: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        title: "Errore!",
      }
    };

    const result = results[submissionStatus];
    return (
      <div className={`${result.className} p-4 rounded mt-6`} role="alert" aria-live="polite">
        <p className="font-bold">{result.title}</p>
        <p>{customMessage}</p>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-[1020px] mx-auto space-y-4">
      <h3 className="h4 text-beige-light text-center mb-3">
        Iscriviti ora gratuitamente!
      </h3>
      <p className="body-tile text-beige-light text-center mb-6">
        Riceverai subito un regalo speciale: una pratica guidata per il rilassamento profondo.
      </p>

      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleInputChange}
        className="w-full px-4 py-3 rounded-full border border-2 focus:outline-none focus:border-2 focus:border-green focus:ring focus:ring-green/20 placeholder:text-beige-dark"
      />

      <div className={`space-y-4 transition-all duration-300 ease-in-out ${showAdditionalFields ? 'opacity-100 max-h-[1000px]' : 'opacity-0 max-h-0 overflow-hidden'}`}>
        <input
          type="text"
          name="name"
          placeholder="Nome"
          value={formData.name}
          onChange={handleInputChange}
          className="w-full px-4 py-3 rounded-full border border-2 focus:outline-none focus:border-2 focus:border-green focus:ring focus:ring-green/20 placeholder:text-beige-dark"
        />

        <input
          type="text"
          name="surname"
          placeholder="Cognome"
          value={formData.surname}
          onChange={handleInputChange}
          className="w-full px-4 py-3 rounded-full border border-2 focus:outline-none focus:border-2 focus:border-green focus:ring focus:ring-green/20 placeholder:text-beige-dark"
        />

        <ProvinceSelect 
          value={formData.province}
          onChange={(selectedProvince) => {
            console.log('Setting province:', selectedProvince);
            setFormData(prev => ({
              ...prev,
              province: selectedProvince
            }));
          }}
          provinces={provinces}
        />

        <DateSelect 
          value={formData.dateOfBirth}
          onChange={(date) => {
            console.log('Date selected:', date);
            setFormData(prev => ({ ...prev, dateOfBirth: date }));
          }}
          className="w-full px-4 py-3 rounded-full border border-2 focus:outline-none focus:border-2 focus:border-green focus:ring focus:ring-green/20 placeholder:text-beige-dark"
        />

        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="policy"
            checked={acceptPolicy}
            onChange={(e) => setAcceptPolicy(e.target.checked)}
            required
            className="mr-2 h-4 w-4 rounded border-gray-300 text-green focus:ring-green"
          />
          <label htmlFor="policy" className="text-sm text-beige-light">
            Accetto la <a href="/privacy" className="text-beige-light hover:text-green underline">politica di utilizzo dei dati</a>
          </label>
        </div>

        <button 
          type="submit"
          className="w-full bg-green hover:bg-accent text-white font-bold py-4 rounded-full transition-colors duration-300"
        >
          ISCRIVITI ORA
        </button>
      </div>

      {submissionStatus && renderResult()}
    </form>
  );
};

export default NewsletterForm;