import React, { useState } from 'react';

const DateSelect = ({ value, onChange }) => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
    "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const dateObj = value ? new Date(value) : null;
  const [selectedDay, setSelectedDay] = useState(dateObj ? dateObj.getDate() : '');
  const [selectedMonth, setSelectedMonth] = useState(dateObj ? dateObj.getMonth() : '');
  const [selectedYear, setSelectedYear] = useState(dateObj ? dateObj.getFullYear() : '');

  const commonStyles = "w-full px-4 py-3 rounded-full border border-2 focus:outline-none focus:border-2 focus:border-green focus:ring focus:ring-green/20 placeholder:text-beige-dark";

  const getMonthName = (monthIndex) => {
    return months[monthIndex] || '';
  };

  const getMonthIndex = (monthName) => {
    return months.findIndex(month => 
      month.toLowerCase().startsWith(monthName.toLowerCase())
    );
  };

  const handleChange = (type, value) => {
    let newDay = selectedDay;
    let newMonth = selectedMonth;
    let newYear = selectedYear;

    switch(type) {
      case 'day':
        if (typeof value === 'string' && value.match(/^\d*$/)) {
          if (parseInt(value) <= 31) {
            newDay = value;
            setSelectedDay(value);
          }
        } else {
          newDay = value;
          setSelectedDay(value);
        }
        break;
      case 'month':
        if (typeof value === 'string' && !value.match(/^\d+$/)) {
          // Handle text input for month names
          const monthIndex = getMonthIndex(value);
          if (monthIndex !== -1) {
            newMonth = monthIndex;
            setSelectedMonth(monthIndex);
          }
        } else {
          // Handle dropdown selection
          newMonth = value;
          setSelectedMonth(value);
        }
        break;
      case 'year':
        if (typeof value === 'string' && value.match(/^\d*$/)) {
          if (value.length <= 4) {
            newYear = value;
            setSelectedYear(value);
          }
        } else {
          newYear = value;
          setSelectedYear(value);
        }
        break;
      default:
        break;
    }

    if (newDay && newMonth !== '' && newYear) {
      const date = new Date(newYear, newMonth, newDay);
      onChange(date);
    }
  };

  return (
    <div className="flex gap-2">
      <div className="w-1/4 relative">
        <input
          type="text"
          value={selectedDay}
          onChange={(e) => handleChange('day', e.target.value)}
          placeholder="Giorno"
          className={commonStyles}
        />
        <select
          value={selectedDay}
          onChange={(e) => handleChange('day', e.target.value)}
          className={`${commonStyles} absolute inset-0 opacity-0 cursor-pointer`}
        >
          <option value="">Giorno</option>
          {days.map(day => (
            <option key={day} value={day}>{day}</option>
          ))}
        </select>
      </div>

      <div className="w-2/5 relative">
        <input
          type="text"
          value={selectedMonth !== '' ? getMonthName(selectedMonth) : ''}
          onChange={(e) => handleChange('month', e.target.value)}
          placeholder="Mese"
          className={commonStyles}
        />
        <select
          value={selectedMonth}
          onChange={(e) => handleChange('month', e.target.value)}
          className={`${commonStyles} absolute inset-0 opacity-0 cursor-pointer`}
        >
          <option value="">Mese</option>
          {months.map((month, index) => (
            <option key={month} value={index}>{month}</option>
          ))}
        </select>
      </div>

      <div className="w-1/3 relative">
        <input
          type="text"
          value={selectedYear}
          onChange={(e) => handleChange('year', e.target.value)}
          placeholder="Anno"
          className={commonStyles}
        />
        <select
          value={selectedYear}
          onChange={(e) => handleChange('year', e.target.value)}
          className={`${commonStyles} absolute inset-0 opacity-0 cursor-pointer`}
        >
          <option value="">Anno</option>
          {years.map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DateSelect;