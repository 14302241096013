import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';

import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../App.css';
import ScrollToSectionButton from '../components/ScrollToSectionButton';
import NewsletterForm from '../components/NewsletterForm'; 

const HeroSection = () => {
  return (
    <section 
      className="relative flex items-center bg-cover bg-center bg-no-repeat"
      style={{ 
        minHeight: 'var(--content-height)',
        backgroundImage: 'url("/images/homepage/bg-hero.png")',
      }}
    >
      {/* Mobile background */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat md:hidden"
        style={{ 
          backgroundImage: 'url("/images/AlessandraHomescreen_2.webp")',
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-beige-light/70  to-beige-light/100"></div>
      </div>
      
      <div className="container relative z-10 mx-auto px-4 flex flex-col md:flex-row items-center">
        {/* Mobile layout */}
        <div className="md:hidden flex flex-col justify-end" style={{ minHeight: 'calc(var(--content-height) + 6rem)' }}>
          <div className=" p-4 rounded-t-3xl">
            <h1 className="h1 text-green mb-4">
              Sblocca il potenziale della tua energia vitale
            </h1>
            <p className="body text-text-dark mb-8">
              Vivi uno stato di benessere e una vita gioiosa ed appagante grazie alla medicina integrata.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <ScrollToSectionButton 
                targetId="integrated-medicine-services"
                className="button bg-beige-light text-beige-dark px-10 py-5 rounded-15 hover:bg-accent hover:text-beige-light transition-colors w-full"
              >
                Leggi i benefici
              </ScrollToSectionButton>
              <Link to="/prenota" className="w-full">
                <button className="button bg-green text-beige-light px-10 py-5 rounded-15 font-semibold hover:bg-accent transition-colors w-full">
                  Prenota una visita
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* Desktop/Tablet layout */}
        <div className="hidden md:flex md:w-1/2 md:pr-8">
          <div>
            <h1 className="h1 text-green mb-4">
              Sblocca il potenziale della tua energia vitale
            </h1>
            <p className="body text-text-dark mb-8">
              Vivi uno stato di benessere e una vita gioiosa ed appagante grazie alla medicina integrata.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <ScrollToSectionButton 
                targetId="integrated-medicine-services"
                className="button bg-beige text-beige-dark px-10 py-5 rounded-15 hover:bg-accent hover:text-beige-light transition-colors"
              >
                Leggi i benefici
              </ScrollToSectionButton>
              <Link to="/prenota">
                <button className="button bg-green text-beige-light px-10 py-5 rounded-15 font-semibold hover:bg-accent transition-colors">
                  Prenota una visita
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden md:block md:w-1/2 mt-8 md:mt-0">
          <div className="bg-beige-dark rounded-30 aspect-[3/4] w-full max-w-md mx-auto">
            <img 
              src="/images/AlessandraHomescreen_2.webp" 
              alt="Fotografia della dottoressa Alessandra Filzoli in un ambiente casalingo" 
              className="w-full h-full border-4 object-cover relative bottom-0 left-0 scale-100 origin-bottom rounded-30"
            />
          </div>
        </div>
      </div>
    </section>
  );
};





const IntegratedMedicineSection = () => {
  return (
    <section className="min-h-[600px] flex items-center bg-green text-white py-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row items-center">
          <div className="w-full sm:w-3/5 sm:pr-8 mb-8 sm:mb-0">
            <h2 className="h2 text-text-light mb-4">
              La Medicina Integrata:
            </h2>
            <h3 className="h3 text-text-light mb-4">
              Un Approccio Completo per la Tua Salute
            </h3>
            <p className="body text-text-light mb-4">
              Con oltre 30 anni di esperienza in medicina integrata, ti guido
              nel migliorare la tua salute a 360 gradi, attraverso i più
              recenti progressi della medicina convenzionale e tecniche di
              medicina cinese, agopuntura, yoga e meditazione
              (introduttiva ed avanzata) per favorire l'equilibrio energetico,
              ridurre il dolore e promuovere il benessere mentale, fisico ed
              emotivo.
            </p>
          </div>
          <div className="w-full sm:w-2/5">
            <div className="rounded-30 aspect-[9/16] w-full max-w-md mx-auto scale-90 relative overflow-hidden">
              <ReactPlayer
                url="/videos/homepage/presentazione_alessandra.mp4"
                width="100%"
                height="100%"
                controls={true}
                light="/videos/homepage/presentazione_alessandra_cover.webp"
                playing={false}
                pip={true}
                stopOnUnmount={false}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload'
                    },
                    forceVideo: true,
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const ServiceCard = ({ title, description, iconPath, buttonText, colorClass, highlightColor }) => (
  <div 
    className={`group ${colorClass} border-4 rounded-30 p-6 flex flex-col items-center text-white h-[400px] transition-all duration-300 ease-in-out transform hover:scale-[1.02] hover:shadow-lg`}
    style={{
      '--highlight-color': highlightColor || '#FF9F1C',
    }}
  >
    <div 
      className={`w-16 h-16 rounded-full mb-4 flex items-center justify-center transition-all duration-300 ease-in-out bg-beige group-hover:bg-[var(--highlight-color)]`}
    >
      <img 
        src={iconPath}
        alt={title}
        className="w-9 h-9"
      />
    </div>
    <h4 className="h4 text-text-light mb-2 text-center">{title}</h4>
    {description ? (
      <p className="body_tile text-text-light mb-4 text-center flex-grow">{description}</p>
    ) : (
      <div className="flex-grow"></div>
    )}
    {buttonText && (
      <button className="button bg-accent text-text-light px-8 py-5 rounded-15 hover:bg-orange-500 transition-colors w-full mt-auto">
        {buttonText}
      </button>
    )}
  </div>
);

const IntegratedMedicineServices = () => {
  const services = [
    {
      title: "Medicina integrata",
      description: "Unisco la medicina tradizionale occidentale con trattamenti innovativi per fornire un approccio olistico alla cura della salute.",
      iconPath: "/icons/medicina_integrata.svg",
      colorClass: "bg-green",
      highlightColor: "#FF9F1C" // Assuming 'accent' color
    },
    {
      title: "Medicina tradizionale, cinese e agopuntura",
      description: "Terapie che promuovono il rilassamento, alleviano il dolore e riequilibrano il sistema fisico-emotivo-energetico-mentale e spirituale.",
      iconPath: "/icons/agopuntura.svg",
      colorClass: "bg-green",
      highlightColor: "#FF9F1C"
    },
    {
      title: "Rimedi naturali e integratori",
      description: "Uso di rimedi naturali ed erboristici per trattare e prevenire le malattie.",
      iconPath: "/icons/rimedi_naturali.svg",
      colorClass: "bg-green",
      highlightColor: "#FF9F1C"
    },
    {
      title: "Medicina dello Stile di Vita",
      description: "Ti aiuto a prendere in mano la tua salute attraverso cambiamenti nelle abitudini quotidiane.",
      iconPath: "/icons/stile_di_vita.svg",
      colorClass: "bg-green",
      highlightColor: "#FF9F1C"
    },
    {
      title: "Tecniche di meditazione avanzata rivolte a tutti",
      description: "Impara tecniche di meditazione avanzate per migliorare il benessere mentale e fisico.",
      iconPath: "/icons/meditazione.svg",
      colorClass: "bg-green",
      highlightColor: "#FF9F1C"
    },
    {
      title: "Leggi di più sulla nostra wiki",
      buttonText: "Coming soon!",
      iconPath: "/icons/book.svg",
      colorClass: "bg-green-dark",
      highlightColor: "#FF9F1C"
    },
  ];

  return (
    <section className="bg-white py-20 px-4">
      <div className="container mx-auto">
        <h2 className="h2 text-text-dark mb-10 text-center">
          Le mie proposte di Medicina Integrata
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};





const EbookSection = () => {
  return (
    <section className="min-h-[600px] flex items-center justify-center bg-beige text-dark-green p-4">
      <div className="max-w-3xl w-full">
        <h2 className="text-4xl font-bold mb-4 mt-8">e-book</h2>
        <div className="flex items-center justify-between mb-8">
          <p className="text-xl pr-4">
            Scarica il mio ebook gratuito sulla medicina cinese per le 4 stagioni
          </p>
          <button className="bg-beige text-white hover:bg-beige-dark px-8 py-5 rounded-30">
            Scarica
          </button>
        </div>
        <div className="rounded-30 overflow-hidden mb-5">
          <img
            src="/images/nutrizione.jpg"
            alt="Hands in meditation pose"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
    </section>
  );
};



const carouselItems = [
  {
    title: 'Nutrizione e abitudini alimentari',
    description: 'Scopri come alimentarti in modo consapevole e sano per prevenire e gestire i disagi e le malattie.',
    image: '/images/trattamenti/600/nutrizione.webp',
  },
  {
    title: 'Esercizio fisico e livello di attività',
    description: 'Implementa un piano di attività fisica che si adatti alle tue esigenze e obiettivi.',
    image: '/images/trattamenti/600/attivita_fisica.webp',
  },
  {
    title: 'Abitudini del sonno',
    description: 'Apprendi come migliorare la qualità del tuo sonno per un benessere generale.',
    image: '/images/trattamenti/600/abitudini_sonno.webp',
  },
  {
    title: 'Gestione dello stress',
    description: ' Impara tecniche efficaci per gestire lo stress e migliorare la tua qualità di vita.',
    image: '/images/trattamenti/600/stress.webp',
  },
  {
    title: 'Integrazione e supplementazione',
    description: 'Scopri quali integratori possono supportare la tua salute.',
    image: '/images/trattamenti/600/integratori.webp',
  },
  // Add more items as needed
];
const ChevronIcon = ({ direction, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`transform ${direction === 'left' ? 'rotate-180' : ''}`}
  >
    <path
      d="M9 18L15 12L9 6"
      stroke="#2f4522"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ArrowContainer = ({ onClick, direction, size = 24 }) => (
  <div
    className={`absolute top-1/2 transform -translate-y-1/2 cursor-pointer z-10 ${
      direction === 'left' ? '-left-12' : '-right-12'
    } hidden md:block`}
    onClick={onClick}
    style={{ width: `${size}px`, height: `${size}px` }}
  >
    <ChevronIcon direction={direction} size={size} />
  </div>
);
const PrevArrow = (props) => <ArrowContainer {...props} direction="left" size={60} />;
const NextArrow = (props) => <ArrowContainer {...props} direction="right" size={60} />;
const CarouselItem = ({ item }) => (
  <div className="px-4 py-2">
    <div className="min-h-[500px] bg-beige rounded-15 overflow-hidden shadow-md transition-all duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1 hover:bg-beige-light">
      <img src={item.image} alt={item.title} className="w-full h-[220px] object-cover" />
      <div className="p-6">
        <h4 className="h4 text-text-dark mb-2 text-center">{item.title}</h4>
        <p className="body_tile text-text-dark mb-4 text-center flex-grow">{item.description}</p>
      </div>
    </div>
  </div>
);
const InfiniteCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1, // Keep this as 1 for button clicks
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "ease",
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    swipeToSlide: true, // Enable drag-to-slide
    touchThreshold: 5, // Make dragging more sensitive
    useCSS: true,
    useTransform: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="w-full min-h-[800px] flex items-center justify-center bg-white text-dark-green p-4">
      <div className="w-full max-w-[1700px] mx-auto px-4 py-12 relative">
        <h2 className="h2 text-text-dark mb-10 text-center">Ti guiderò attraverso:</h2>
        <Slider {...settings}>
          {carouselItems.map((item, index) => (
            <div key={index} className="mx-2">
              <CarouselItem item={item} />
            </div>
          ))}
        </Slider>
      </div>  
    </section>
  );
};


const PatologieItems = [
  {
    title: 'Disturbi gastrointestinali',
    description: "Sindrome dell'intestino irritabile, reflusso gastroesofageo, disbiosi intestinale (stitichezza, diarrea) , malattia di Crohn.",
    image: '/images/patologie/600/maldipancia.webp',
  },
  {
    title: 'Problemi metabolici',
    description: ' Diabete di tipo 2, sindrome metabolica, ipercolesterolemia, sovrappeso.',
    image: '/images/patologie/600/metabolismo.webp',
  },
  {
    title: 'Disturbi del sonno',
    description: 'Insonnia, apnee notturne, disturbi del ritmo circadiano.',
    image: '/images/patologie/600/insonnia.webp',
  },
  {
    title: 'Problemi cardiovascolari',
    description: 'Ipertensione, aritmie, insufficienza cardiaca, prevenzione delle malattie cardiovascolari.',
    image: '/images/patologie/600/cuore.webp',
  },
  {
    title: 'Disturbi muscoloscheletrici',
    description: 'Dolore cronico, fibromialgia, osteoartrite, lombalgia, cervicalgia, sciatalgia, la spalla dolorosa.',
    image: '/images/patologie/600/dolori.webp',
  },
  {
    title: 'Malattie neurologiche e della sfera psichica',
    description: 'cefalea, emicrania, nevralgia del nervo faciale e del trigemino, herpes zoster, vertigini.',
    image: '/images/patologie/600/malditesta.webp',
  },
  {
    title: 'Malattie autoimmuni',
    description: 'Artrite reumatoide, lupus, sclerosi multipla, tiroidite di Hashimoto',
    image: '/images/patologie/600/autoimmune.webp',
  },
  {
    title: 'Disturbi d’ansia e del tono dell’umore',
    description: 'Ansia, depressione, attacchi di panico, instabilità emotiva , gestione dello stress.',
    image: '/images/patologie/600/ansia.webp',
  },
  {
    title: 'Squilibri ormonali',
    description: "Sindrome premestruale (PMS), menopausa, ipo-ipertiroidismo, sindrome dell'ovaio policistico (PCOS)",
    image: '/images/patologie/600/ormonali.webp',
  },
];
const FadeCard = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="relative cursor-pointer mx-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full h-64 transition-opacity duration-300 rounded-lg overflow-hidden">
        {/* Front of the card */}
        <div className={`absolute w-full h-full transition-opacity duration-300 ${isHovered ? 'opacity-0' : 'opacity-100'}`}>
          <div 
            className="w-full h-full overflow-hidden shadow-md rounded-15"
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center rounded-15 justify-center p-4">
              <h4 className="h4 text-text-light text-center">{item.title}</h4>
            </div>
          </div>
        </div>

        {/* Back of the card */}
        <div className={`absolute w-full h-full bg-green-dark rounded-15 p-4 flex items-center justify-center transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
          <p className="body_tile text-text-light text-center">{item.description}</p>
        </div>
      </div>
    </div>
  );
};


const PatologieCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1, // Keep this as 1 for button clicks
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "ease",
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    swipeToSlide: true, // Enable drag-to-slide
    touchThreshold: 5, // Make dragging more sensitive
    useCSS: true,
    useTransform: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="min-h-[700px] flex flex-col items-center bg-green text-dark-green p-4">
      <div className="w-full max-w-6xl mx-auto mt-20 px-4 py-12">
        <h2 className="h2 text-text-light mb-5">Le principali patologie che tratto:</h2>
        <h3 className="h3 text-text-light">Per quale ragione si rivolgono a me i miei clienti?:</h3>
      </div>
      <div className="w-full max-w-[1700px] mx-auto px-4 mb-4">
        <Slider {...settings}>
          {PatologieItems.map((item, index) => (
            <FadeCard key={index} item={item} />
          ))}
        </Slider>
      </div>
    </section>
  );
};



const NewsletterSubscription = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [customMessage, setCustomMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setSubmissionStatus('error');
      setCustomMessage('Per favore, inserisci un indirizzo email valido.');
      return;
    }

    try {
      const result = await subscribeToNewsletter(email);
      setSubmissionStatus(result.status);
      setCustomMessage(result.message);
    } catch (error) {
      console.error('Error:', error);
      setSubmissionStatus('error');
      setCustomMessage('Si è verificato un errore imprevisto. Per favore, riprova più tardi.');
    }
  };

  const subscribeToNewsletter = async (email) => {
    try {
      const response = await fetch('/api/subscribe-newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });
  
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return {
          status: 'error',
          message: 'Si è verificato un errore durante l\'elaborazione della tua richiesta. Per favore, riprova più tardi.'
        };
      }
    } catch (error) {
      console.error('Subscription error:', error);
      throw error;
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit} className="bg-white p-8 rounded-30">
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-15 focus:outline-none focus:ring-2 focus:ring-yellow-500"
        />
      </div>
      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="policy"
          checked={acceptPolicy}
          onChange={(e) => setAcceptPolicy(e.target.checked)}
          required
          className="mr-2"
        />
        <label htmlFor="policy" className="text-sm text-gray-700">
          Accetto la <a href="/privacy" className="text-blue-600 hover:underline">politica di utilizzo dei dati</a>
        </label>
      </div>
      <button
        type="submit"
        className="w-full button bg-beige-dark text-beige-light px-4 py-5 rounded-15 hover:bg-accent transition-colors"
      >
        Iscriviti
      </button>
    </form>
  );

  const renderResult = () => {
    const results = {
      success: {
        className: "bg-green-100 border-l-4 border-green-500 text-green-700",
        title: "Iscrizione confermata!",
      },
      info: {
        className: "bg-blue-100 border-l-4 border-blue-500 text-blue-700",
        title: "Informazione",
      },
      error: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        title: "Errore!",
      }
    };

    const result = results[submissionStatus];
    return (
      <div className={`${result.className} p-4 rounded`} role="alert">
        <p className="font-bold">{result.title}</p>
        <p>{customMessage}</p>
      </div>
    );
  };
  const handleSubmissionComplete = (result) => {
    console.log('Newsletter submission completed:', result);
  };
  return (
    <section className="min-h-[700px] flex items-center justify-center bg-beige text-dark-green p-4">
      <div className="max-w-4xl w-full px-4 sm:px-6 lg:px-8">
        <h2 className="h2 text-text-dark text-center">Ricevi Consigli e Aggiornamenti</h2>
        <p className="body text-text-dark mb-8 text-center">Iscriviti alla mia newsletter per rimanere sempre in contatto!</p>
        
        <div className="flex justify-center mb-8">
          <div id="spiegazione" className="bg-beige-dark p-8 rounded-15 shadow-lg">
                <NewsletterForm 
                  onSubmissionComplete={handleSubmissionComplete}
                />
              </div>
        </div>

        <AnimatePresence>
          {isFormVisible && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className="overflow-hidden"
            >
              {submissionStatus === null ? renderForm() : renderResult()}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

const CenteredDivider = ({ height = '3px' }) => {
  return (
    <div className="flex justify-center w-full my-8">
      <div 
        className="w-full max-w-[1200px] bg-green-dark rounded-full" 
        style={{ height }}
      />
    </div>
  );
};




const Home = () => {
  return (
    <div className="flex flex-col">
      <HeroSection />

      <IntegratedMedicineSection />

      <div id="integrated-medicine-services">
        <IntegratedMedicineServices />
      </div>
      <CenteredDivider />
      <InfiniteCarousel />

      <PatologieCarousel />

      <NewsletterSubscription />

    </div>
  );
};

export default Home;