import React from 'react';
import { Mail, Instagram } from 'lucide-react';
import { ChevronDown } from 'lucide-react';
import ScrollToSectionButton from '../components/ScrollToSectionButton';

const HeroSection = () => {
  return (
    <section 
      className="relative flex items-center bg-beige-light"
      style={{ minHeight: 'var(--content-height)' }}
    >
      {/* Mobile background */}
      <div 
        className="absolute inset-0 bg-cover bg-top bg-no-repeat md:hidden"
        style={{ 
          backgroundImage: 'url("/images/about/alessandra_about.webp")',
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-beige-light/75 to-beige-light"></div>
      </div>
      
      <div className="container relative z-10 mx-auto px-4 flex flex-col md:flex-row items-start">
        {/* Mobile layout */}
        <div className="md:hidden flex flex-col justify-center w-full" style={{ minHeight: 'calc(var(--content-height) - 0rem)' }}>
          <div className="p-4 rounded-t-3xl">
            <h1 className="h1 text-text-dark mb-4">
              Vi parlo di me...
            </h1>
            <p className="body text-text-dark mt-4 mb-6">
              La mia passione per la vita, la Natura e le anime che la abitano ha sempre guidato
              i miei passi, anche quando ancora non ne avevo piena consapevolezza. 
              È stata la Natura, la mia stella polare, a condurmi verso comprensioni profonde, 
              fornendomi gli strumenti principali per il mio lavoro.
            </p>
            <ScrollToSectionButton 
              targetId="spiegazione"
              className="flex h4 items-center justify-center px-6 py-5 w-full bg-beige-dark text-white font-bold rounded-full hover:bg-accent transition-colors duration-300 shadow-md"
            >
              Leggi di più
              <ChevronDown className="ml-2" size={20} />
            </ScrollToSectionButton>
          </div>
        </div>

        {/* Desktop/Tablet layout */}
        <div className="hidden md:flex md:w-1/2 md:pr-8 flex-col">
          <h1 className="h1 text-green mb-4">
            Vi parlo di me...
          </h1>
          <p className="body text-text-dark mt-10 mb-10">
            La mia passione per la vita, la Natura e le anime che la abitano ha sempre guidato
            i miei passi, anche quando ancora non ne avevo piena consapevolezza. 
            È stata la Natura, la mia stella polare, a condurmi verso comprensioni profonde, 
            fornendomi gli strumenti principali per il mio lavoro. Ma il vero inizio del mio 
            cammino di cura e di ricerca ha preso forma durante gli studi di Medicina e Chirurgia 
            presso l'Università degli Studi di Milano.
          </p>
          <ScrollToSectionButton 
            targetId="spiegazione"
            className="flex h4 items-center justify-center px-6 py-5 mt-4 bg-beige-dark text-white font-bold rounded-full hover:bg-accent transition-colors duration-300 shadow-md"
          >
            Leggi di più
            <ChevronDown className="ml-2" size={20} />
          </ScrollToSectionButton>
        </div>
        <div className="hidden md:block md:w-1/2 mt-8 md:mt-0">
          <div className="rounded-30 aspect-[3/4] w-full max-w-md mx-auto overflow-hidden">
            <img 
              src="/images/about/alessandra_about.webp" 
              alt="Dottoressa Alessandra Filzoli"
              className="w-full h-full object-cover object-top"
            />
          </div>
        </div>
      </div>
    </section>
  );
};


const InfoSessionComponent = () => {
  return (
    <section className="min-h-[600px] flex items-center bg-green-dark text-white py-20 p-4">
      <div className="container mx-auto px-4">
        <div className="w-full">
          <h2 className="h2 text-center text-text-light mb-4">
            Il Mio Percorso nella Medicina Integrata
          </h2>
          <h3 className="h3 text-center text-text-light mb-10">
            Un Viaggio tra Oriente e Occidente
          </h3>
          <div className="space-y-10 body text-text-light ">
            <div className='mb-10'>
              <h4 className="font-bold text-center ">Le Fondamenta Occidentali</h4>
              <p>
                La formazione medica occidentale ha rappresentato le fondamenta della mia comprensione del corpo umano, del disagio e delle sofferenze che le persone vivono. Quegli anni di studio intenso mi hanno offerto una visione rigorosa e scientifica della medicina, preparandomi ad affrontare le complessità della cura. Tuttavia, qualcosa in me cercava oltre. La strada da percorrere era lunga e, nel cuore, già sapevo che esisteva un modo più completo di accostarsi alla salute e al benessere.
              </p>
            </div>
            <div className='mb-10'>
              <h4 className="font-bold text-center ">L'Incontro con la Medicina Orientale</h4>
              <p>
                Fu un'esperienza personale, un caso in famiglia, a cambiarmi profondamente. L'incontro con il dott. Franz Jost, medico internista e grande esperto di Medicina Tradizionale Cinese (MTC), a Lugano, non solo salvò una persona a me cara, ma mi avviò verso un nuovo orizzonte, quello della medicina orientale. Iniziai a vedere la salute come un equilibrio più sottile, qualcosa che non si limita al corpo fisico, ma che coinvolge l'energia vitale e l'armonia profonda dell'individuo.
              </p>
            </div>

            <div className='mb-10'>
              <h4 className="font-bold text-center ">Il Percorso di Integrazione</h4>
              <p>
                Quasi in contemporanea alla mia laurea in Medicina, completai il corso quadriennale di Agopuntura presso la scuola So-Wen di Milano, immergendomi con entusiasmo nell'esercizio di quella che sentivo essere una nuova, affascinante passione. Non fu facile conciliare le due visioni — quella occidentale, radicata nella scienza, e quella orientale, più olistica e energetica. Ma con il tempo, queste due prospettive iniziarono a dialogare fra loro, arricchendosi reciprocamente.
              </p>
            </div>

            <div className='mb-10'>
              <h4 className="font-bold text-center ">Incontri Formativi</h4>
              <p>
                Il mio desiderio di comprendere e integrare questi due mondi mi portò ad avere il privilegio di incontrare figure di spicco come il dott. Nguyen Van Ghi, medico vietnamita che seguii a lungo durante la sua permanenza presso l'ospedale di Ravenna. Con lui appresi come trattare patologie neurologiche, un campo che ha sempre catturato il mio interesse. A Marsiglia, il dott. Albert Gourion, suo stretto collaboratore, ampliò la mia comprensione della MTC, mentre l'incontro con il dott. Giovanni Maciocia, considerato il "padre della medicina tradizionale cinese in Europa", mi diede accesso a una profondità ancora maggiore. Ognuno di questi incontri mi ha segnato profondamente, forgiando in me un'attitudine di continua ricerca e crescita.
              </p>
            </div>            
            
            <div className='mb-10'>
              <h4 className="font-bold text-center ">Esplorazioni Oltre la Medicina Tradizionale</h4>
              <p>
                Tuttavia, la mia esplorazione non si è fermata qui. Spinta dall'impulso di trovare una visione ancora più completa della salute, ho abbracciato ambiti diversi, spaziando oltre la medicina tradizionale. 
              </p>
            </div>   

            <div className='mb-10'>
              <h4 className="font-bold text-center ">Il Respiro come Chiave</h4>
              <p>
                Tra i tanti strumenti che ho integrato nel mio lavoro, il respiro è diventato una chiave centrale. La Medicina Tradizionale Cinese lo chiama "nutrimento celeste", e nel corso degli anni ho affinato tecniche di respirazione e rilassamento, grazie soprattutto all'insegnamento di Selene Calloni Williams, Maestra di Yoga Sciamanico. Il Mantra Madre, cuore dello Yoga Sciamanico, è stato per me una rivelazione, una porta verso una comprensione più profonda dell'essere umano nella sua totalità.
              </p>
            </div>  
          </div>
        </div>
      </div>
    </section>
  );
};

const About = () => {
  return (
    <div className="flex flex-col">
      <HeroSection />
      <div id="spiegazione">
      <InfoSessionComponent />
      </div>
    </div>
  );
};

export default About;
