import React from 'react';
import { ChevronDown } from 'lucide-react';
import ScrollToSectionButton from '../components/ScrollToSectionButton';
import NewsletterForm from '../components/NewsletterForm';

const HeroSection = () => {
  return (
    <section 
      className="relative flex items-center bg-beige-light"
      style={{ minHeight: 'var(--content-height)' }}
    >
      <div 
        className="absolute inset-0 bg-cover bg-top bg-no-repeat md:hidden"
        style={{ 
          backgroundImage: 'url("/images/newsletter/autumn-composition.webp")',
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-beige-light/75 to-beige-light"></div>
      </div>
      
      <div className="container relative z-10 mx-auto px-4 flex flex-col md:flex-row items-start">
        <div className="md:hidden flex flex-col justify-center w-full" style={{ minHeight: 'calc(var(--content-height) - 0rem)' }}>
          <div className="p-4 rounded-t-3xl">
            <h1 className="h1 text-text-dark mb-4">
              Ritrova il tuo equilibrio ogni sabato mattina
            </h1>
            <p className="body text-text-dark mt-4 mb-6">
              Ogni settimana, nella tua casella di posta, ricevi consigli 
              preziosi per prenderti cura di te, ispirati alla Medicina 
              Integrata e alla Medicina Tradizionale Cinese.
            </p>
            <ScrollToSectionButton 
              targetId="newsletter"
              className="flex h4 items-center justify-center px-6 py-5 w-full bg-beige-dark text-white font-bold rounded-full hover:bg-accent transition-colors duration-300 shadow-md"
            >
              Iscriviti ora
              <ChevronDown className="ml-2" size={20} />
            </ScrollToSectionButton>
          </div>
        </div>

        <div className="hidden md:flex md:w-1/2 md:pr-8 flex-col">
          <h1 className="h1 text-green mb-4">
            Ritrova il tuo equilibrio ogni sabato mattina
          </h1>
          <p className="body text-text-dark mt-10 mb-10">
            Ogni settimana, nella tua casella di posta, ricevi consigli 
            preziosi per prenderti cura di te, ispirati alla Medicina 
            Integrata e alla Medicina Tradizionale Cinese.
          </p>
          <ScrollToSectionButton 
            targetId="newsletter"
            className="flex h4 items-center justify-center px-6 py-5 mt-4 bg-beige-dark text-white font-bold rounded-full hover:bg-accent transition-colors duration-300 shadow-md"
          >
            Iscriviti ora
            <ChevronDown className="ml-2" size={20} />
          </ScrollToSectionButton>
        </div>
        <div className="hidden md:block md:w-1/2 mt-8 md:mt-0">
          <div className="rounded-15 aspect-[3/4] w-full max-w-md mx-auto overflow-hidden">
            <img 
              src="/images/newsletter/autumn-composition.webp" 
              alt="Dottoressa Alessandra Filzoli"
              className="w-full h-full object-cover object-top"
            />
          </div>
        </div>
      </div>
    </section>
  );
};


const NewsletterFeatures = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
      <div className="p-6 bg-green rounded-15 shadow-md">
        <h3 className="h4 text-beige-light mb-4">Rimedi pratici e naturali</h3>
        <p className="body-tile text-text-light">Per migliorare il tuo benessere quotidiano.</p>
      </div>
      <div className="p-6 bg-green rounded-15 shadow-md">
        <h3 className="h4 text-beige-light mb-4">Approfondimenti sulla salute</h3>
        <p className="body-tile text-text-light">Dal ritmo delle stagioni al significato dei sintomi secondo la MTC.</p>
      </div>
      <div className="p-6 bg-green rounded-15 shadow-md">
        <h3 className="h4 text-beige-light mb-4">Esercizi e rituali</h3>
        <p className="body-tile text-text-light">Semplici pratiche per ritrovare calma, energia e vitalità.</p>
      </div>
      <div className="p-6 bg-green rounded-15 shadow-md">
        <h3 className="h4 text-beige-light mb-4">Nuova prospettiva</h3>
        <p className="body-tile text-text-light">Come unire corpo, mente e spirito per sentirti davvero bene.</p>
      </div>
    </div>
  );
};

const NewsletterSection = () => {
  const handleSubmissionComplete = (result) => {
    console.log('Newsletter submission completed:', result);
  };

  return (
    <>
      <section className="bg-white py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <h2 className="h2 text-green text-center mb-16">Cosa troverai nella mia newsletter?</h2>
            <NewsletterFeatures />
            <div className="text-center mb-12">
              <h3 className="h3 text-green mb-4">Perché iscriverti?</h3>
              <p className="body text-text-dark">
                La salute è un viaggio, non una destinazione. Con la mia newsletter, voglio guidarti passo dopo passo verso un approccio più consapevole e armonioso al tuo benessere.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="newsletter" className="bg-green py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div id="spiegazione" className="bg-beige-dark p-8 rounded-15 shadow-lg">
              <NewsletterForm 
                onSubmissionComplete={handleSubmissionComplete}
              />
            </div>

            <div className="text-center mt-12">
              <p className="h4 text-beige-light">"Prendersi cura di sé è il primo passo per vivere meglio"</p>
              <p className="body-tile text-text-dark italic mt-4">
                Dott.ssa Alessandra Filzoli<br />
                Medico di Medicina Integrata e Medicina Tradizionale Cinese
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Newsletter2 = () => {
  return (
    <div className="flex flex-col">
      <HeroSection />
      <NewsletterSection />
    </div>
  );
};

export default Newsletter2;