import * as React from "react"

const ProvinceSelect = ({ value, onChange, provinces }) => {
  const [inputValue, setInputValue] = React.useState(value || '')
  const [showSuggestions, setShowSuggestions] = React.useState(false)
  const wrapperRef = React.useRef(null)

  // Keep input value synced with parent value
  React.useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const filteredProvinces = provinces.filter(province =>
    province.toLowerCase().includes(inputValue.toLowerCase())
  )

  const handleInputChange = (e) => {
    const newValue = e.target.value
    setInputValue(newValue)
    setShowSuggestions(true)
    
    // If the exact input matches a province, update the parent
    const exactMatch = provinces.find(
      province => province.toLowerCase() === newValue.toLowerCase()
    )
    if (exactMatch) {
      onChange(exactMatch)
    } else {
      onChange('') // Clear the parent value if no exact match
    }
  }

  const handleSelectProvince = (province) => {
    console.log('Province selected in component:', province)
    setInputValue(province)
    setShowSuggestions(false)
    onChange(province)  // Update parent state
  }

  // Handle clicking outside of the component
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div className="relative" ref={wrapperRef}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setShowSuggestions(true)}
        placeholder="Cerca e seleziona provincia..."
        className="w-full px-4 py-3 rounded-full border border-2 focus:outline-none focus:border-2 focus:border-green focus:ring focus:ring-green/20 placeholder:text-beige-dark"
      />
      {showSuggestions && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredProvinces.length === 0 ? (
            <div className="px-4 py-2 text-sm text-gray-500">
              Nessuna provincia trovata.
            </div>
          ) : (
            <ul>
              {filteredProvinces.map((province) => (
                <li
                  key={province}
                  onClick={() => handleSelectProvince(province)}
                  className={`px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer flex items-center ${
                    province === value ? 'bg-green/10' : ''
                  }`}
                >
                  {province}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

export default ProvinceSelect